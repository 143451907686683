import "../style/About.scss";

function About() {
  return (
    <div className="about-container">
      <div>
        <h1>What is Ninja Rift?</h1>
        <p>
          Ninja Rift is an exciting role-playing game that will transport you to a
          world full of ninjas and thrilling
          adventures.
          In Ninja Rift, you will live unforgettable moments as you immerse
          yourself in this unique experience. Customize your own ninja, learn
          techniques, and face challenging battles. Forge strategic alliances
          and make crucial decisions that will influence your path. Become the
          legendary ninja you have always dreamed of being and leave an
          indelible mark on the history of Ninja Rift.
        </p>
        <p>
          Let yourself be carried away by a wave of nostalgia as you relive
          unforgettable moments. Awaken your dearest memories and get ready to
          embark on a new and exciting adventure that will make you feel the
          passion and excitement of being a true ninja. Discover what it means
          to relive those magical moments and become a legend in Ninja Rift!
        </p>
      </div>
    </div>
  );
}

export default About;
