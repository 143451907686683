import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment-timezone"; // Import moment-timezone
import "../style/ArenaRanking.scss";
import VillageMembers from "./VillageMembers";

function VillageRanking() {
  const [villages, setVillages] = useState([]);
  const [countdown, setCountdown] = useState(null);
  const [seasonNumber, setSeasonNumber] = useState(null);
  const [seasonEndTimestamp, setSeasonEndTimestamp] = useState(null);
  const [activeVillageId, setActiveVillageId] = useState(null);
  const [activeVillageName, setActiveVillageName] = useState("");
  const [activeVillageMembers, setActiveVillageMembers] = useState([]);
  const [viewingMainRanking, setViewingMainRanking] = useState(true);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchSeasonData = async () => {
      try {
        const seasonResponse = await axios.get("/api/refresh_time_arena_website.php");

        if (seasonResponse.data) {
          const { season, season_end } = seasonResponse.data;

          setSeasonNumber(season);

         
          const seasonEndCST = moment.tz(season_end, "America/Chicago").valueOf(); //Convert to CST 
          setSeasonEndTimestamp(seasonEndCST);
        } else {
          console.error("Invalid season data format:", seasonResponse.data);
        }
      } catch (error) {
        console.error("Error fetching season data:", error);
      }
    };

    fetchSeasonData();
  }, []);

  useEffect(() => {
    const fetchVillageData = async () => {
      try {
        const villageResponse = await axios.get(
          "api/village_ranking_website.php"
        );

        if (villageResponse.data && Array.isArray(villageResponse.data)) {
          const updatedVillages = villageResponse.data.map((village) => ({
            ...village,
          }));

          console.log("Fetched Villages Data:", updatedVillages); // Debug: Log fetched data

          setVillages(updatedVillages);
        } else {
          console.error(
            "Invalid village ranking data format:",
            villageResponse.data
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchVillageData();
    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchVillageData, 2000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (seasonEndTimestamp) {
      const countdownIntervalId = setInterval(() => {
        // Get current CST time
        const currentCSTTime = moment().tz("America/Chicago").valueOf(); // Get current time in CST

        // Calculate remaining time in CST
        const remainingTime = Math.max(0, seasonEndTimestamp - currentCSTTime);
        setCountdown(remainingTime);
      }, 1000);

      return () => clearInterval(countdownIntervalId);
    }
  }, [seasonEndTimestamp]);

  const fetchVillageMembers = async (villageId, villageName) => {
    try {
      console.log(`Fetching members for villageId: ${villageId}`); // Debug: Log the villageId

      const response = await axios.get(
        `api/detail_village_website.php?village_id=${villageId}`
      );

      if (response.data && response.data.members) {
        console.log("Fetched Members Data:", response.data.members); // Debug: Log fetched members
        setActiveVillageMembers(response.data.members);
        setActiveVillageId(villageId);
        setActiveVillageName(villageName);
        setViewingMainRanking(false);
      } else {
        console.error("Invalid village member data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching village members:", error);
    }
  };

  const goBackToMainRanking = () => {
    setActiveVillageId(null);
    setActiveVillageName("");
    setActiveVillageMembers([]);
    setViewingMainRanking(true);
  };

  const formatTime = (time) => {
    // Ensure time is formatted correctly
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = formatTime(countdown);

  const getVillageNameById = (id) => {
    switch (id) {
      case 1:
        return "Wind Village";
      case 2:
        return "Fire Village";
      case 5:
        return "Water Village";
      case 3:
        return "Lightning Village";
      case 4:
        return "Earth Village";
      default:
        return "Unknown Village";
    }
  };

  return (
    <div className="village-ranking">
      <h1 className="village-ranking-header">
        {seasonNumber ? `Season ${seasonNumber} Arena Village Ranking` : "Arena Village Ranking"}
      </h1>

      <div className="countdown-container">
        <div className="countdown-clock">
          <div className="clock-segment">
            <div className="clock-number">{days}</div>
            <div className="clock-label">Days</div>
          </div>
          <div className="clock-segment">
            <div className="clock-number">{hours}</div>
            <div className="clock-label">Hours</div>
          </div>
          <div className="clock-segment">
            <div className="clock-number">{minutes}</div>
            <div className="clock-label">Minutes</div>
          </div>
          <div className="clock-segment">
            <div className="clock-number">{seconds}</div>
            <div className="clock-label">Seconds</div>
          </div>
        </div>
      </div>

      {viewingMainRanking ? (
        <div className="ranking-table-container">
          <table className="ranking-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Village Name</th>
                <th>Trophies</th>
                <th>Gap from Rank 1</th>
                <th>Gap</th>
              </tr>
            </thead>
            <tbody>
              {villages.map((village, index) => {
                console.log("Rendering village:", village); // Debug: Log each village
                const gapFromRank1 =
                  villages[0].village_trophies - village.village_trophies;
                const prevVillage = villages[index - 1];
                const gapFromAbove = prevVillage
                  ? prevVillage.village_trophies - village.village_trophies
                  : null;

                return (
                  <tr
                    key={index}
                    onClick={() =>
                      fetchVillageMembers(village.village_id, getVillageNameById(village.village_id))
                    }
                    className={index % 2 === 0 ? "row-even" : "row-odd"}
                    style={{ cursor: "pointer" }} // Make rows appear clickable
                  >
                    <td>{index + 1}</td>
                    <td>{getVillageNameById(village.village_id)}</td>
                    <td>{village.village_trophies}</td>
                    <td>{gapFromRank1}</td>
                    <td>{gapFromAbove}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <VillageMembers
          villageId={activeVillageId} // Pass villageId to VillageMembers
          villageName={activeVillageName}
          onBackClick={goBackToMainRanking}
        />
      )}
      <div className="pagination"></div>
    </div>
  );
}

export default VillageRanking;
