import "../style/Footer.scss";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="first-container">
          <Link to="/" className="nav-link">
            <img src={logo} alt="#logo" />
          </Link>
          <p>
            A social ninja game where you create your own ninja, battle AI foes or other players, and immerse yourself in thrilling combat and strategic challenges.
          </p>
        </div>

        <div className="fourth-container">
          <h3> Social Media</h3>
          <a href="https://discord.gg/ninjarift" rel="noreferrer" target="_blank">
            <ion-icon name="logo-discord"></ion-icon>
          </a>
          <a href="https://www.facebook.com/PlayNinjaRift" rel="noreferrer" target="_blank">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <a href="https://www.youtube.com/@playninjarift" rel="noreferrer" target="_blank">
            <ion-icon name="logo-youtube"></ion-icon>
          </a>
          <a href="https://www.instagram.com/playninjarift" rel="noreferrer" target="_blank">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
          <a href="https://www.tiktok.com/@playninjarift" rel="noreferrer" target="_blank">
            <ion-icon name="logo-tiktok"></ion-icon>
          </a>
        </div>

        <div className="second-container">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="./PatchNotes">Patch Notes</Link>
            </li>
            <li>
              <Link to="./Login">Login</Link>
            </li>
          </ul>
        </div>
        
        <div className="third-container">
          <h3>Legal</h3>
          <ul>
            <li>
              <Link to="/termsandconditions">Terms and Conditions</Link>
            </li>
            <li>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/DeleteAccount">Delete Your Data </Link>
            </li>
          </ul>
        </div>
        
      </div>
      <div className="rights">© 2021 - {new Date().getFullYear()} Ninja Rift</div>
    </div>
  );
}

export default Footer;
