import React from "react";
import "../style/TermsAndConditions.scss";

function TermsAndConditions() {
  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>
      
      <div className="section">
        <h2>1. Introduction</h2>
        <p>Welcome to Ninja Rift! By accessing or using our game, you agree to be bound by these Terms and Conditions. If you do not agree with these terms, please do not use our game.</p>
      </div>

      <div className="section">
        <h2>2. Eligibility</h2>
        <p>To play Ninja Rift, you must be at least 13 years old. By playing, you confirm that you meet this age requirement.</p>
      </div>

      <div className="section">
        <h2>3. Account Information</h2>
        <p>While we do not require personal information such as email addresses or usernames, you must have an IP address and login information to play the game.</p>
      </div>

      <div className="section">
        <h2>4. Game Rules</h2>
        <p>You agree to follow all game rules and guidelines. Any cheating, hacking, or other unauthorized use of the game is strictly prohibited and may result in a permanent ban.</p>
      </div>

      <div className="section">
        <h2>5. Intellectual Property</h2>
        <p>All content within Ninja Rift, including but not limited to graphics, game mechanics, and text, is the property of Ninja Rift and is protected by copyright laws. You may not use any content without explicit permission from Ninja Rift.</p>
      </div>

      <div className="section">
        <h2>6. Limitation of Liability</h2>
        <p>Ninja Rift is provided on an "as is" and "as available" basis. We do not guarantee that the game will be error-free or uninterrupted. To the maximum extent permitted by law, Ninja Rift will not be liable for any damages arising from the use or inability to use the game.</p>
      </div>

      <div className="section">
        <h2>7. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting. Your continued use of Ninja Rift after changes are posted constitutes your acceptance of the new terms.</p>
      </div>

      <div className="section">
        <h2>8. Termination</h2>
        <p>We may terminate or suspend your access to Ninja Rift at any time, without prior notice or liability, for any reason, including if you breach these Terms and Conditions.</p>
      </div>

      <div className="section">
        <h2>9. Governing Law</h2>
        <p>These Terms and Conditions are governed by the laws of the jurisdiction in which Ninja Rift operates. Any disputes arising from these terms will be resolved in the courts of that jurisdiction.</p>
      </div>

      <div className="section">
        <h2>10. Contact Us</h2>
        <p>If you have any questions about these Terms and Conditions, please contact us through our official support channels.</p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
