import "../style/Payment.scss";
import Tokens from "./Tokens";
import emblemplus from "../img/emblemplus.png";
import emblem from "../img/emblem.png";
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';

function Payment() {
  const accType = localStorage.getItem("acc_type");
  const username = localStorage.getItem("username");
  const accountId = localStorage.getItem("acc_id");
  const currentTokens = localStorage.getItem("tokens");
  const [selectedCharId, setSelectedCharId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [firstAvailableCharId, setFirstAvailableCharId] = useState(null);

  const characterSlots = Array(6).fill(null).map((_, index) => ({
    slot: index + 1,
    charId: JSON.parse(localStorage.getItem("characters") || "[]")[index]?.char_id || null
  }));

  // Find first available character ID
  useEffect(() => {
    const firstChar = characterSlots.find(slot => slot.charId !== null);
    if (firstChar) {
      setFirstAvailableCharId(firstChar.charId);
    }
  }, []);

  const handlePayPalCheckout = async (itemName, price, type) => {
    try {
      setIsLoading(true);
      
      // Map the frontend product types to backend package IDs
      const getPackageId = (type, price) => {
        switch(type) {
          case 'emblem':
            return 'package_7'; // Emblem & 2200 Tokens package
          case 'emblemplus':
            return 'package_8'; // Emblem Plus package
          case 'emblemplus_upgrade':
            return 'package_9'; // Emblem Plus Upgrade package
          case 'tokens':
            // Map token amounts to package IDs
            switch(price) {
              case '49.99':
                return 'package_1'; // 30,000 tokens
              case '24.99':
                return 'package_2'; // 13,500 tokens
              case '9.99':
                return 'package_3'; // 5,000 tokens
              case '4.99':
                return 'package_4'; // 2,200 tokens
              default:
                return null;
            }
          default:
            return null;
        }
      };

      if (!firstAvailableCharId && type !== 'tokens') {
        Swal.fire({
          icon: 'warning',
          title: 'Character Required',
          text: 'Please create a character before making this purchase.',
        });
        setIsLoading(false);
        return;
      }

      if (type === 'tokens' && !selectedCharId) {
        Swal.fire({
          icon: 'warning',
          title: 'Character Required',
          text: 'Please select a character before purchasing tokens.',
        });
        setIsLoading(false);
        return;
      }

      const packageId = getPackageId(type, price);
      if (!packageId) {
        throw new Error('Invalid package type or price');
      }

      const form = document.createElement('form');
      form.method = 'post';
      form.action = 'https://www.paypal.com/cgi-bin/webscr';
      
      const customData = {
        username: username,
        accountId: accountId,
        packageid: packageId,
        charid: type === 'tokens' ? selectedCharId : firstAvailableCharId,
        timestamp: new Date().toISOString()
      };
      
      const fields = {
        cmd: '_xclick',
        business: 'ninjariftofficial@gmail.com',
        item_name: type === 'tokens' 
          ? `${itemName} for ${username} (Character: ${selectedCharId})`
          : `${itemName} for ${username} (Character: ${firstAvailableCharId})`,
        amount: price,
        currency_code: 'USD',
        return: 'https://playninjarift.com',
        cancel_return: 'https://playninjarift.com',
        notify_url: 'https://playninjalegends.com/paypal/paypal_webhook.php',
        no_shipping: '1',
        no_note: '1',
        rm: '2',
        custom: JSON.stringify(customData),
        item_number: packageId,
        invoice: `${packageId}_${Date.now()}_${Math.random().toString(36).substr(2, 5)}`
      };

      // Log the data being sent
      console.log('PayPal checkout data:', fields);

      Object.entries(fields).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);

    } catch (error) {
      console.error('PayPal checkout error:', error);
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Checkout Failed',
        text: 'There was an error processing your checkout. Please try again.',
      });
    }
  };

  // Only enable emblem purchases if there's at least one character
  const isEmblemPurchaseDisabled = !firstAvailableCharId || isLoading;

  return (
    <div className="payment-container">
      <div className="payment-header">
        Payment method: PayPal
      </div>

      {accType === "Free User" && (
        <>
          <div className="buy-emblem">
            <div className="emblem">
              <img src={emblem} alt="Ninja Emblem" />
              <div className="emblem-text">
                <h3>Ninja Emblem</h3>
                <p>Enjoy premium features</p>
              </div>
            </div>
            <div className="emblem-price">
              <div className="price-text">
                <h3>9.99</h3>
                <p>USD</p>
              </div>
              <div>
                <button 
                  onClick={() => handlePayPalCheckout('Ninja Emblem', '9.99', 'emblem')}
                  disabled={isEmblemPurchaseDisabled}
                  className={isEmblemPurchaseDisabled ? 'disabled' : ''}
                >
                  {isLoading ? 'Processing...' : !firstAvailableCharId ? 'Create Character First' : 'Buy'}
                </button>
              </div>
            </div>
          </div>

          <br />

          <div className="buy-emblem-plus">
            <div className="emblem">
              <img src={emblemplus} alt="Emblem+" />
              <div className="emblem-text">
                <h3>Emblem+</h3>
                <p>Enjoy all premium features.</p>
              </div>
            </div>
            <div className="emblem-price">
              <div className="price-text">
                <h3>19.99</h3>
                <p>USD</p>
              </div>
              <div>
                <button 
                  onClick={() => handlePayPalCheckout('Emblem+', '19.99', 'emblemplus')}
                  disabled={isEmblemPurchaseDisabled}
                  className={isEmblemPurchaseDisabled ? 'disabled' : ''}
                >
                  {isLoading ? 'Processing...' : !firstAvailableCharId ? 'Create Character First' : 'Buy'}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {accType === "Premium User" && (
        <div className="buy-emblem-plus">
          <div className="emblem">
            <img src={emblemplus} alt="Emblem+" />
            <div className="emblem-text">
              <h3>Emblem+</h3>
              <p>Enjoy all premium features and more</p>
            </div>
          </div>
          <div className="emblem-price">
            <div className="price-text">
              <h3>9.99</h3>
              <p>USD</p>
            </div>
            <div>
              <button 
                onClick={() => handlePayPalCheckout('Emblem+ Upgrade', '9.99', 'emblemplus_upgrade')}
                disabled={isEmblemPurchaseDisabled}
                className={isEmblemPurchaseDisabled ? 'disabled' : ''}
              >
                {isLoading ? 'Processing...' : !firstAvailableCharId ? 'Create Character First' : 'Buy'}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="tokens">
        <div className="character-selector">
          <label htmlFor="character-select">Select Character Slot:</label>
          <select 
            id="character-select"
            value={selectedCharId}
            onChange={(e) => setSelectedCharId(e.target.value)}
            className="character-dropdown"
          >
            <option value="">Select a character slot</option>
            {characterSlots.map((slot) => (
              <option 
                key={slot.slot} 
                value={slot.charId}
                disabled={!slot.charId}
              >
                Slot {slot.slot} {slot.charId ? `(Character ID: ${slot.charId})` : '(Empty)'}
              </option>
            ))}
          </select>
        </div>
        
        <div className="tokens-container">
          <Tokens 
            amount="30000" 
            price="49.99"
            username={username} 
            accountId={accountId}
            charId={selectedCharId}
            currentTokens={currentTokens} 
            disabled={!selectedCharId}
            type="tokens"
            onCheckout={handlePayPalCheckout}
          />
          <Tokens 
            amount="13500" 
            price="24.99"
            username={username} 
            accountId={accountId}
            charId={selectedCharId}
            currentTokens={currentTokens} 
            disabled={!selectedCharId}
            type="tokens"
            onCheckout={handlePayPalCheckout}
          />
          <Tokens 
            amount="5000" 
            price="9.99"
            username={username} 
            accountId={accountId}
            charId={selectedCharId}
            currentTokens={currentTokens} 
            disabled={!selectedCharId}
            type="tokens"
            onCheckout={handlePayPalCheckout}
          />
          <Tokens 
            amount="2200" 
            price="4.99"
            username={username} 
            accountId={accountId}
            charId={selectedCharId}
            currentTokens={currentTokens} 
            disabled={!selectedCharId}
            type="tokens"
            onCheckout={handlePayPalCheckout}
          />
        </div>
      </div>
    </div>
  );
}

export default Payment;
