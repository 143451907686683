// components/Wiki/Subcategories.jsx
import React, { useMemo } from 'react';
import { ChevronRight, Flag, Scroll } from 'lucide-react';

const Subcategories = ({ subcategories, onSubCategoryClick }) => {
  // Helper function to extract season number
  const getSeasonNumber = (name) => {
    const match = name.match(/Season (\d+)/);
    return match ? parseInt(match[1]) : 0;
  };

  // Sort subcategories by season number
  const sortedSubcategories = useMemo(() => {
    if (!subcategories || subcategories.length === 0) {
      return [];
    }

    return [...subcategories].sort((a, b) => {
      const numA = getSeasonNumber(a.name);
      const numB = getSeasonNumber(b.name);
      return numA - numB;
    });
  }, [subcategories]);

  if (!subcategories || subcategories.length === 0) {
    return (
      <div className="text-center py-8 text-gray-400">
        No subcategories found
      </div>
    );
  }

  return (
    <div className="subcategories-grid">
      {sortedSubcategories.map((subcategory) => (
        <div 
          key={subcategory.subcategory_id}
          className="subcategory-card"
          onClick={() => onSubCategoryClick(subcategory)}
        >
          <div className="subcategory-content">
            <div className="category-wrapper">
              <div className="category-icon">
                {subcategory.icon_url ? (
                  <img 
                    src={subcategory.icon_url} 
                    alt={subcategory.name}
                    className="w-20 h-20 object-contain"
                  />
                ) : (
                  <Scroll className="w-20 h-20" />
                )}
              </div>
              <div className="category-info">
                <h3 className="text-lg font-semibold text-white mb-1">
                  {subcategory.name}
                </h3>
                <p className="text-sm text-gray-400">
                  {subcategory.description || subcategory.name}
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-auto">
              {subcategory.is_endpoint ? (
                <Flag className="w-5 h-5 text-blue-400" />
              ) : (
                <ChevronRight className="w-5 h-5 text-blue-400" />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Subcategories;