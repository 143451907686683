import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment-timezone"; // Import moment-timezone
import "../style/CrewRanking.scss";
import CrewMembers from "./CrewMembers";
import CrewCastleDetails from "./CrewCastleDetails";

function CrewRanking() {
  const [crews, setCrews] = useState([]);
  const [castleData, setCastleData] = useState({});
  const [countdown, setCountdown] = useState(null);
  const [seasonNumber, setSeasonNumber] = useState(null);
  const [phaseNumber, setPhaseNumber] = useState(null);
  const [seasonEndTimestamp, setSeasonEndTimestamp] = useState(null);
  const [activeCastle, setActiveCastle] = useState(null);
  const [activeCastleData, setActiveCastleData] = useState([]);
  const [activeCrewMembers, setActiveCrewMembers] = useState([]);
  const [activeCrewId, setActiveCrewId] = useState(null);
  const [activeCrewName, setActiveCrewName] = useState("");
  const [view, setView] = useState('main'); // Manage the view state with one variable
  const crewIntervalRef = useRef(null);
  const castleIntervalRef = useRef(null);

  // Fetch season and phase data
  useEffect(() => {
    const fetchSeasonData = async () => {
      try {
        const seasonResponse = await axios.get("/api/refresh_time_crew_website.php");

        if (seasonResponse.data) {
          const { season, phase, season_end } = seasonResponse.data;
          setSeasonNumber(season);
          setPhaseNumber(phase);

          // Convert season end time to CST
          const seasonEndCST = moment.tz(season_end, "America/Chicago").valueOf(); // Convert to CST timestamp
          setSeasonEndTimestamp(seasonEndCST);
        } else {
          console.error("Invalid season data format:", seasonResponse.data);
        }
      } catch (error) {
        console.error("Error fetching season data:", error);
      }
    };

    fetchSeasonData();
  }, []);

  // Fetch overall crew ranking data
  useEffect(() => {
    const fetchCrewData = async () => {
      try {
        const crewResponse = await axios.get("/api/crew_ranking_website.php");

        if (crewResponse.data && Array.isArray(crewResponse.data)) {
          const updatedCrews = crewResponse.data.map((crew) => ({
            ...crew,
          }));

          setCrews(updatedCrews);
        } else {
          console.error("Invalid crew ranking data format:", crewResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCrewData();
    if (!crewIntervalRef.current) {
      crewIntervalRef.current = setInterval(fetchCrewData, 2000);
    }

    return () => {
      if (crewIntervalRef.current) {
        clearInterval(crewIntervalRef.current);
        crewIntervalRef.current = null;
      }
    };
  }, []);

  // Fetch castle data from the API
  useEffect(() => {
    const fetchCastleData = async () => {
      try {
        const response = await axios.get("/api/crew_ranking_castles_website.php");

        if (response.data) {
          setCastleData(response.data);
        } else {
          console.error("Invalid castle ranking data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching castle data:", error);
      }
    };

    fetchCastleData();
    if (!castleIntervalRef.current) {
      castleIntervalRef.current = setInterval(fetchCastleData, 2000);
    }

    return () => {
      if (castleIntervalRef.current) {
        clearInterval(castleIntervalRef.current);
        castleIntervalRef.current = null;
      }
    };
  }, []);

  // Countdown timer for the season end
  useEffect(() => {
    if (seasonEndTimestamp) {
      const countdownIntervalId = setInterval(() => {
        // Get current CST time
        const currentCSTTime = moment().tz("America/Chicago").valueOf(); // Get current time in CST

        // Calculate remaining time in CST
        const remainingTime = Math.max(0, seasonEndTimestamp - currentCSTTime);
        setCountdown(remainingTime);
      }, 1000);

      return () => clearInterval(countdownIntervalId);
    }
  }, [seasonEndTimestamp]);

  // Fetch crew members for a specific crew
  const fetchCrewMembers = async (crewId, crewName) => {
    try {
      // Ensure the URL is correct
      const url = `/api/detail_crew_website.php?crew_id=${encodeURIComponent(crewId)}`;
      console.log("Fetching crew members for URL:", url); // Debugging line

      const response = await axios.get(url);

      if (response.data && response.data.members) {
        setActiveCrewMembers(response.data.members);
        setActiveCrewId(crewId);
        setActiveCrewName(crewName);
        setView('crewMembers'); // Set view to crew members
      } else {
        console.error("Invalid crew member data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching crew members:", error);
    }
  };

  // Handle clicking on a castle button
  const handleCastleClick = (castleName) => {
    setActiveCastle(castleName);
    setActiveCastleData(castleData[castleName] || []);
    setView('castleDetails'); // Set view to castle details
  };

  // Go back to the main ranking view
  const goBackToMainRanking = () => {
    setActiveCastle(null);
    setActiveCastleData([]);
    setActiveCrewId(null);
    setActiveCrewName("");
    setActiveCrewMembers([]);
    setView('main'); // Reset view to main ranking
  };

  const formatTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = formatTime(countdown);

  return (
    <div className="crew-ranking">
      <h1 className="crew-ranking-header">
        {seasonNumber && phaseNumber 
          ? `Season ${seasonNumber} Phase ${phaseNumber} Crew Ranking` 
          : "Crew Ranking"}
      </h1>

      <div className="countdown-container">
        <div className="countdown-clock">
          <div className="clock-segment">
            <div className="clock-number">{days}</div>
            <div className="clock-label">Days</div>
          </div>
          <div className="clock-segment">
            <div className="clock-number">{hours}</div>
            <div className="clock-label">Hours</div>
          </div>
          <div className="clock-segment">
            <div className="clock-number">{minutes}</div>
            <div className="clock-label">Minutes</div>
          </div>
          <div className="clock-segment">
            <div className="clock-number">{seconds}</div>
            <div className="clock-label">Seconds</div>
          </div>
        </div>
      </div>

      {/* Castle Buttons */}
      {view === 'main' && (
        <div className="castle-buttons-container">
          {Object.keys(castleData).map((castleName) => {
            const topCrew = castleData[castleName][0];
            return (
              <button
                key={castleName}
                className="castle-button"
                onClick={() => handleCastleClick(castleName)}
              >
                <h2>{castleName} Castle</h2>
                {topCrew ? (
                  <div>
                    <p>Crew: {topCrew.crew_name}</p>
                    {phaseNumber === 1 && topCrew.boss_kills && <p>Boss Points: {topCrew.boss_kills}</p>}
                  </div>
                ) : (
                  <p>No data available</p>
                )}
              </button>
            );
          })}
        </div>
      )}

      {/* Main Ranking Table */}
      {view === 'main' ? (
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Name</th>
              <th>Master</th>
              <th>Member No.</th>
              <th>Damage</th>
              <th>Gap from Rank 1</th>
              <th>Gap</th>
            </tr>
          </thead>
          <tbody>
            {crews.map((crew, index) => {
              const gapFromRank1 = crews[0].crew_damage - crew.crew_damage;
              const prevCrew = crews[index - 1];
              const gapFromAbove = prevCrew ? prevCrew.crew_damage - crew.crew_damage : null;

              return (
                <tr
                  key={index}
                  onClick={() => fetchCrewMembers(crew.crew_id, crew.crew_name)}
                  className={index % 2 === 0 ? "row-even" : "row-odd"}
                  style={{ cursor: "pointer" }}
                >
                  <td>{index + 1}</td>
                  <td>{crew.crew_name}</td>
                  <td>{crew.character_name}</td>
                  <td>{`${crew.crew_members}/${crew.crew_max_members}`}</td>
                  <td>{crew.crew_damage}</td>
                  <td>{gapFromRank1}</td>
                  <td>{gapFromAbove}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : view === 'crewMembers' ? ( // Check if viewing crew members
        <CrewMembers
          members={activeCrewMembers}
          crewName={activeCrewName}
          onBackClick={goBackToMainRanking}
        />
      ) : view === 'castleDetails' ? ( // Check if viewing castle details
        <CrewCastleDetails
          phaseNumber={phaseNumber}
          castleName={activeCastle}
          castleData={activeCastleData}
          onBackClick={goBackToMainRanking}
          fetchCrewMembers={fetchCrewMembers} // Pass fetchCrewMembers function as prop
        />
      ) : null}
    </div>
  );
}

export default CrewRanking;
