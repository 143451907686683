import "../style/Dashboard.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { useAuth } from "../context/AuthContext";
import token from "../img/token.png";
import emblemplus from "../img/emblemplus.png";
import emblem from "../img/emblem.png";
import freeuser from "../img/freeuser.webp";
import Payment from './Payment';
import ChangeUsernamePopup from './ChangeUsernamePopup';

function Dashboard() {
    const navigate = useNavigate();
    const { login, logout } = useAuth();
    const charactersJson = localStorage.getItem("characters");
    const [userData, setUserData] = useState({
        username: localStorage.getItem("username"),
        accountId: localStorage.getItem("acc_id"),
        tokens: localStorage.getItem("tokens"),
        accountType: localStorage.getItem("acc_type"),
        characters: charactersJson ? JSON.parse(charactersJson) : []
    });
    
    const verifySession = async () => {
        const acc_id = localStorage.getItem("acc_id");
        const sessionkey = localStorage.getItem("sessionkey");
        
        if (!acc_id || !sessionkey) {
            handleInvalidSession();
            return;
        }

        $.ajax({
            type: "POST",
            url: "https://playninjalegends.com/account/verify_session.php",
            data: {
                acc_id: acc_id,
                sessionkey: sessionkey
            },
            success(data) {
                if (data.valid) {
                    // Update user data with fresh data from server
                    const accType = data.acc_type === 2 ? "Emblem Plus User" : 
                                  data.acc_type === 1 ? "Premium User" : "Free User";
                    
                    localStorage.setItem("username", data.username);
                    localStorage.setItem("tokens", data.tokens);
                    localStorage.setItem("acc_type", accType);
                    localStorage.setItem("characters", JSON.stringify(data.characters || []));
                    
                    setUserData({
                        username: data.username,
                        accountId: data.acc_id,
                        tokens: data.tokens,
                        accountType: accType,
                        characters: data.characters || []
                    });
                    login();
                } else {
                    handleInvalidSession();
                }
            },
            error() {
                // On error, we'll keep the user logged in but won't update the data
                console.error("Failed to verify session");
            }
        });
    };

    const handleInvalidSession = () => {
        localStorage.clear();
        logout();
        navigate("/Login");
    };
    
    useEffect(() => {
        // Verify session immediately on component mount
        verifySession();

        // Set up periodic session verification (every 5 minutes)
        const intervalId = setInterval(verifySession, 5 * 60 * 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setUserData(prev => ({
            ...prev,
            username: localStorage.getItem("username")
        }));
    };

    const getAccountTypeImage = (accountType) => {
        switch(accountType) {
            case "Emblem Plus User":
                return <img src={emblemplus} width="50" alt="Emblem Plus User" />;
            case "Premium User":
                return <img src={emblem} width="30" alt="Premium User" />;
            case "Free User":
                return <img src={freeuser} width="50" alt="Free User" />;
            default:
                return null;
        }
    };

    return (
        <div className="content-container">
            <h1 className="h1-dashboard">Account Information</h1>
            <div className="data-container">
                <div className="data-item">
                    <label htmlFor="username">Account Username:</label>
                    <span id="username">{userData.username}</span>
                    <button className="change-username-btn" onClick={openPopup}>Change Username</button>
                </div>
                <div className="data-item">
                    <label htmlFor="acc_id">Account ID:</label>
                    <span id="acc_id">{userData.accountId}</span>
                </div>
                <div className="data-item">
                    <label htmlFor="acc_type">Account Type:</label>
                    <span id="acc_type">{userData.accountType}</span>
                    {getAccountTypeImage(userData.accountType)}
                </div>
                <div className="data-item">
                    <label htmlFor="tokens">Tokens:</label>
                    <span id="tokens">{userData.tokens}</span>
                    <img src={token} width="25" alt="Token" />
                </div>
                <div className="data-item">
                    <label>Characters:</label>
                    <span>{userData.characters.length} character(s) available</span>
                </div>
            </div>
            
            <Payment 
                username={userData.username}
                accountId={userData.accountId}
                currentTokens={userData.tokens}
                accountType={userData.accountType}
                characters={userData.characters}
            />

            {isPopupOpen && (
                <ChangeUsernamePopup onClose={closePopup} />
            )}
        </div>
    );
}

export default Dashboard;
