import "../style/Tokens.scss";
import token from "../img/token.png";
import { useState } from "react";
import Swal from 'sweetalert2';

function Tokens({ 
    amount, 
    price, 
    discount, 
    off,
    username,
    accountId,
    charId,
    currentTokens,
    disabled,
    onCheckout
}) {
    const [isLoading, setIsLoading] = useState(false);

    const handleBuy = () => {
        if (disabled || !charId) {
            Swal.fire({
                icon: 'warning',
                title: 'Character Required',
                text: 'Please select a character before purchasing tokens.',
            });
            return;
        }

        setIsLoading(true);
        
        try {
            // Use the shared checkout handler from Payment component
            onCheckout(`${amount} Tokens`, price, 'tokens');
        } catch (error) {
            console.error('Token purchase error:', error);
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Purchase Failed',
                text: 'There was an error processing your token purchase. Please try again.',
            });
        } finally {
            // Reset loading state after a short delay to ensure visual feedback
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    };

    return (
        <div className={`package-container ${disabled ? 'disabled' : ''}`}>
            <div className="token-amount">
                <div className="img">
                    <img src={token} alt="" />
                    <div>
                        <h3>{amount}</h3>
                        <p>Tokens</p>
                    </div>
                </div>
                {discount && (
                    <div className="discount">
                        <h3>{discount}</h3>
                        <p>{off}</p>
                    </div>
                )}
            </div>

            <div className="price-container">
                <div className="price-text">
                    <h3>{Number(price).toFixed(2)}</h3>
                    <p>USD</p>
                </div>
                <div>
                    <button 
                        onClick={handleBuy}
                        disabled={isLoading || disabled}
                        className={`${isLoading ? 'loading' : ''} ${disabled ? 'disabled' : ''}`}
                    >
                        {isLoading ? 'Processing...' : disabled ? 'Select Character' : 'Buy'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Tokens;
