import React from "react";
import "../style/PrivacyPolicy.scss";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy for Ninja Rift</h1>
      
      <div className="section">
        <h2>1. Introduction</h2>
        <p>Ninja Rift is committed to protecting your privacy. This Privacy Policy explains what information we collect, how we use it, and your rights regarding your information.</p>
      </div>

      <div className="section">
        <h2>2. Information We Collect</h2>
        <p>IP Address: We collect your IP address for security and operational purposes.</p>
        <p>Login Information: We collect login information to manage your access to the game.</p>
        <p>Google User Data: If you use Google services within our app, we collect and use your Google user data as per Google's policies.</p>
      </div>

      <div className="section">
        <h2>3. How We Use Your Information</h2>
        <p>Security: Your IP address is used to protect against unauthorized access and to ensure the security of the game.</p>
        <p>Game Access: Login information is used to grant you access to the game and manage your game session.</p>
        <p>Google User Data: We use Google user data only for the purposes disclosed and in compliance with Google’s Limited Use requirements.</p>
      </div>

      <div className="section">
        <h2>4. Sharing Your Information</h2>
        <p>We do not share your IP address, login information, or Google user data with any third parties, except when required by law or to protect our rights.</p>
      </div>

      <div className="section">
        <h2>5. Data Retention</h2>
        <p>We retain your IP address and login information for as long as necessary to provide you with access to Ninja Rift and to comply with legal obligations. Google user data is retained as per Google’s policies and your consent.</p>
      </div>

      <div className="section">
        <h2>6. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access: Request a copy of the information we hold about you.</li>
          <li>Correction: Request corrections to any inaccurate information.</li>
          <li>Deletion: Request deletion of your information, subject to legal and operational limitations.</li>
        </ul>
      </div>

      <div className="section">
        <h2>7. Security Measures</h2>
        <p>We implement appropriate technical and organizational measures to protect your information against unauthorized access, alteration, disclosure, or destruction.</p>
      </div>

      <div className="section">
        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and your continued use of Ninja Rift constitutes your acceptance of the updated policy.</p>
      </div>

      <div className="section">
        <h2>9. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy or wish to exercise your rights, please contact us through our official support channels.</p>
      </div>

      <div className="section">
        <h2>10. Compliance with Google API Policies</h2>
        <p>Hosting: This Privacy Policy is hosted within the domain that hosts our homepage.</p>
        <p>Accessibility: The Privacy Policy is linked on our homepage and is also linked from the OAuth consent screen on the Google API Console.</p>
        <p>Consistency: The Privacy Policy linked on our homepage and the app’s OAuth consent screen is the same.</p>
        <p>Disclosure: This Privacy Policy, along with our product-specific privacy disclosures, explains how we access, use, store, and/or share Google user data.</p>
        <p>Limited Use: Our use of Google user data is limited to the practices disclosed in this Privacy Policy and conforms with Google’s Limited Use requirements.</p>
        <p>In-App Notification: In-product privacy notifications are prominently displayed in our app interface so that users can easily find this information.</p>
        <p>Updates: This Privacy Policy and in-product privacy notifications are kept up to date about how our app uses Google user data.</p>
      </div>

      <div className="section">
        <h2>11. How We Use Google User Data</h2>
        <p>We only access and use Google user data to provide and enhance your gaming experience. This includes:</p>
        <ul>
          <li>Authenticating your identity and providing access to your game account.</li>
          <li>Syncing your game progress across multiple devices.</li>
          <li>Enhancing game features based on your preferences and gameplay behavior.</li>
          <li>Complying with legal obligations and resolving any disputes.</li>
        </ul>
        <p>We ensure that our use of Google user data is transparent and consistent with the permissions you have granted. We do not use this data for any unauthorized purposes.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
