import React, { useEffect, useState, useRef } from "react";
import axios from "axios"; // Import axios for making API requests
import "../style/VillageMembers.scss";

function VillageMembers({ villageName, villageId, onBackClick }) {
  const [membersData, setMembersData] = useState([]);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Function to fetch members data from the API
    const fetchMembersData = async () => {
      if (!villageId) {
        console.error("Invalid villageId:", villageId); // Debug: Log if villageId is invalid
        return; // Exit if villageId is not valid
      }

      try {
        console.log(`Fetching members for villageId: ${villageId}`); // Debug: Log the villageId

        const response = await axios.get(
          `api/detail_village_website.php?village_id=${villageId}`
        );

        console.log("API Response:", response.data); // Debug: Log the entire API response

        if (response.data && response.data.members) {
          const members = response.data.members;

          // Calculate gaps after fetching data
          const updatedMembers = members.map((member, index) => {
            const gapFromRank1 = members[0].trophies - member.trophies;
            const prevMember = members[index - 1];
            const gapFromAbove = prevMember
              ? prevMember.trophies - member.trophies
              : null;

            return {
              ...member,
              gapFromRank1,
              gapFromAbove,
            };
          });

          setMembersData(updatedMembers);
        } else {
          console.error("Invalid village member data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching village members:", error);
      }
    };

    // Initial data fetch
    fetchMembersData();

    // Set up the interval to fetch new data every 2 seconds
    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchMembersData, 2000);
    }

    // Cleanup the interval on component unmount or if villageId changes
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [villageId]); // Dependency on villageId to refetch data if village changes

  return (
    <div className="village-members">
      <h2 className="village-members-header">{villageName} Members</h2>
      <button className="back-button" onClick={onBackClick}>
        Back to Main Ranking
      </button>

      <table className="member-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Character Name</th>
            <th>Level</th>
            <th>Trophies</th>
            <th>Gap from Rank 1</th>
            <th>Gap</th>
          </tr>
        </thead>
        <tbody>
          {membersData.slice(0, 100).map((member, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "row-even" : "row-odd"}
            >
              <td>{index + 1}</td> {/* Display rank based on index */}
              <td>{member.character_name}</td>
              <td>{member.character_level}</td>
              <td>{member.trophies}</td>
              <td className="gap-from-rank-1-column">{member.gapFromRank1}</td> {/* Display gap from rank 1 */}
              <td className="gap-column">{member.gapFromAbove !== null ? member.gapFromAbove : '-'}</td> {/* Display gap from above */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default VillageMembers;
