import "../style/Landing.scss";
import steam_logo from "../icons/steamstore.png";
import playstore_logo from "../icons/playstore.png";

function Landing() {
  return (
    <div className="landing-container">
      <div className="content-container">
        <div className="text-container">
          <h2>Join The Battle:</h2>
          <h1>Ninja Rift Awaits You!</h1>
          <p>
            Immerse yourself in Ninja Rift, where the classic and the new come
            together. Completely rebuilt, this game takes you into a world
            packed with ninja battles, sneaky tactics, and epic showdowns.
            Experience the thrill of the past in a sleek, modern way. It's time
            to carve your name in the records of{" "}
            <span>
              <b>Ninja Rift!</b>
            </span>
          </p>
          <div className="button-container">
            <a
              href="https://store.steampowered.com/app/3095380/Ninja_Rift"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={steam_logo} alt="Steam Logo" className="store-logo" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=air.NinjaRift"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={playstore_logo}
                alt="Play Store Logo"
                className="store-logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
